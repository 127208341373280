import { createSelector } from 'reselect';

import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { readonlyArray } from 'lib/readonly_record';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { RootState } from 'redux/root_reducer';

export const getIterationsById = (state: RootState) => state.entities.iterations;

export const getIterationForId = (state: RootState, iterationId: number) => getIterationsById(state)[iterationId];

export const getIterationsForCurrentWorkspace = createSelector(
  getCurrentWorkspaceId,
  getCurrentOrganization,
  getIterationsById,
  (workspaceId, organization, iterationsById) => {
    if (!organization?.featureFlags.iterationBucket) {
      return [];
    }

    const workspaceIterations = Object.values(iterationsById)
      .filter((iteration) => iteration.workspaceId === workspaceId)
      .sort((a, b) => a.priority.localeCompare(b.priority));

    return readonlyArray(workspaceIterations);
  }
);
