// Keep in sync with com.liquidplanner.backend.util
// Use camel case
export enum FeatureFlag {
  groupCapacity = 'groupCapacity',
  jiraIntegration = 'jiraIntegration',
  jiraMultiProjectPull = 'jiraMultiProjectPull',
  systemColumnsRefactor = 'systemColumnsRefactor',
  jiraCustomFieldMapping = 'jiraCustomFieldMapping',
  jiraTimesheetWorklog = 'jiraTimesheetWorklog',
  jiraHierarchy = 'jiraHierarchy',
  dashboardFetchWidgetsSeparately = 'dashboardFetchWidgetsSeparately',
  peopleReportRefactor = 'peopleReportRefactor',
  itemReportRefactor = 'itemReportRefactor',
  iterationBucket = 'iterationBucket',
  landingPageUpdate = 'landingPageUpdate',
  disablePendo = 'disablePendo',
  storyPoints = 'storyPoints',
  totalRow = 'totalRow',
  widgetQuickFilter = 'widgetQuickFilter',
  showAssignmentGridTypeInPortfolioGrid = 'showAssignmentGridTypeInPortfolioGrid',
}
